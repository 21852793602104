// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cocktails-js": () => import("./../../../src/pages/cocktails.js" /* webpackChunkName: "component---src-pages-cocktails-js" */),
  "component---src-pages-custom-bottles-js": () => import("./../../../src/pages/custom-bottles.js" /* webpackChunkName: "component---src-pages-custom-bottles-js" */),
  "component---src-pages-discover-js": () => import("./../../../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-private-label-js": () => import("./../../../src/pages/private-label.js" /* webpackChunkName: "component---src-pages-private-label-js" */),
  "component---src-pages-spirits-js": () => import("./../../../src/pages/spirits.js" /* webpackChunkName: "component---src-pages-spirits-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

